import { Box, Container, SimpleGrid } from "@chakra-ui/react";
import React, { useState, useEffect} from "react";

const FpAdBlock = ({ page, block }) => {

    const [adsCount, setAdsCount] = useState(6);
    const [adsMdCount, setAdsMdCount] = useState(3);

    useEffect(() => {
        googletag.cmd.push(function() { googletag.display('div-gpt-ad-1725549538301-0') });
        googletag.cmd.push(function() { googletag.display('div-gpt-ad-1725549591422-0') });
        googletag.cmd.push(function() { googletag.display('div-gpt-ad-1725549674006-0') });
        googletag.cmd.push(function() { googletag.display('div-gpt-ad-1725549730608-0') });
        googletag.cmd.push(function() { googletag.display('div-gpt-ad-1725549760008-0') });
        googletag.cmd.push(function() { googletag.display('div-gpt-ad-1725549787878-0') });

        googletag.cmd.push(function () {
            googletag.pubads().addEventListener('slotRenderEnded', function (ad) {
                /* add the is-hidden class to the parent of empty ads */
                if (ad.isEmpty) {
                    
                    const elementId = ad.slot.getSlotElementId();
                    const adEle = document.getElementById(elementId).parentNode;
                    if (adEle) {
                        adEle.classList.add('is-hidden');

                        const adEleParent = adEle.parentNode;
                        if (adEleParent) {
                            if (adEleParent.classList.contains('ad-block')) {
                                adEleParent.classList.add('is-hidden');
                            }
                        }
                    }
                }
            });
        });

        setTimeout(function () {
            const hiddenElements = document.querySelectorAll('.is-hidden').length;
            setAdsCount(6 - hiddenElements);
            if ((6 - hiddenElements) <= 4) {
                setAdsMdCount(2);
            }
        }, 1000);
        
    }, []);

    return (
        <Box className="signals-wrapper" pt={10} pb={10}>
            <Container maxW={'7xl'}>
                <SimpleGrid columns={{ base: 2, md: adsMdCount, lg: adsCount }} spacing={2}>
                    <Box className="ad-block">
                        <div id='div-gpt-ad-1725549538301-0' >
                        </div>
                    </Box>
                    <Box className="ad-block">
                        <div id='div-gpt-ad-1725549591422-0'>
                        </div>
                    </Box>
                    <Box className="ad-block">
                        <div id='div-gpt-ad-1725549674006-0'>
                        </div>
                    </Box>
                    <Box className="ad-block">
                        <div id='div-gpt-ad-1725549730608-0'>
                        </div>
                    </Box>
                    <Box className="ad-block">
                        <div id='div-gpt-ad-1725549760008-0'>
                        </div>
                    </Box>
                    <Box className="ad-block">
                        <div id='div-gpt-ad-1725549787878-0'>
                        </div>
                    </Box>
                </SimpleGrid>
            </Container>
        </Box>
    )
};

export default FpAdBlock;